<template>
  <div class="page">
    <van-nav-bar title="续期申请" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="content">
      <div class="form">
        <div>
          <div>
            <van-field
              v-model.trim="form.delayDays"
              clearable
              maxlength="2"
              label="延期天数"
              placeholder="请输入延期天数"
              type="digit"
              required
              @input="onDelayDaysChange"
            />
            <van-field
              v-if="!isExpire"
              readonly
              :value="dueDate"
              label="到期日"
              placeholder="输入天数后自动计算"
            />
            <van-field
              v-model.trim="form.applyReason"
              maxlength="200"
              rows="3"
              autosize
              label="申请原因"
              type="textarea"
              required
              placeholder="请填写申请原因"
            />
          </div>
        </div>
      </div>
      <div v-if="!isExpire">
        <div class="head">申请记录</div>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="list-box"
          >
            <DelayItem
              v-for="(item, index) in list"
              :item="item"
              :key="index"
              class="item"
            ></DelayItem>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>

    <div class="actions">
      <van-button type="primary" block @click="handleSubmit"
        >提交申请</van-button
      >
    </div>
  </div>
</template>
<script>
import * as _ from "lodash";
import Api from "@/api/caseHandle";
import { Toast } from "vant";
import dayjs from "dayjs";

const DEFAULT_FORM = {
  applyReason: "",
  delayDays: null,
  // refNo: "",
  // tenantSysId: "",
};

export default {
  components: {
    DelayItem: () => import("./components/DelayItem"),
  },
  data() {
    return {
      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },

      homeDetail: null,

      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed: {
    dueDate() {
      const { form, homeDetail } = this;
      const { delayDays } = form;
      if (delayDays == null || delayDays == "" || homeDetail == null) {
        return null;
      }
      return dayjs(homeDetail.expireTime, "YYYY-MM-DD HH:mm:ss")
        .add(+form.delayDays, "days")
        .format("YYYY-MM-DD");
    },
    isExpire() {
      return this.$route.query.isExpire;
    },
  },
  methods: {
    async handleSubmit() {
      try {
        const loading = Toast.loading();
        const params = _.cloneDeep(this.form);
        if (!params.delayDays) {
          Toast(`请输入延期天数`);
          return;
        }
        if (params.delayDays <= 0 || params.delayDays > 30) {
          Toast(`延期天数只能为1-30天`);
          return;
        }
        if (!params.applyReason) {
          Toast(`申请原因不能为空`);
          return;
        }
        console.log('1 :>> ', this.$route.query.homeNo);
        params.refNoList = this.$route.query.homeNo.split(',');
        var res = await Api.addDelayApply({
          ...params,
        });

        loading.close();
        Toast.success(res.message);

        this.form = { ...DEFAULT_FORM };

        await this.onRefresh();
      } catch (error) {
        console.log('error :>> ', error);
        Toast.fail(error);
      }
    },

    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        var res = await Api.findDelayApplyList({
          refNoEq: this.$route.query.homeNo,
          sourceEq: "OUTSOURCE_HOME",
        });
        const { data } = res;
        this.list = data;
        this.finished = true;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    async onRefresh() {
      await this.onLoad();
    },

    onDelayDaysChange() {
      this.findDetailByHomeNo();
    },

    // 家访详情
    async findDetailByHomeNo() {
      if(this.isExpire) return
      try {
        const res = await Api.findDetailByHomeNo(this.$route.query.homeNo);
        this.homeDetail = res.data;
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  mounted() {
    this.findDetailByHomeNo();
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.page {
  height: 100%;
  background: @pageBg;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;

    .head {
      background: #fff;
      padding: 10px 10px;
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: bold;
    }

    .form {
      margin-bottom: 10px;
    }
  }

  .actions {
    .van-button {
      border-radius: 0px;
    }
  }
}
</style>
